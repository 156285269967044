import React from "react";

export default function Footer() {
  return (
    <footer className="flex flex-col justify-center mx-auto text-center mt-16 -mb-24">
      <a
        href="https://github.com/darreninglis"
        className="p-4 w-fit mx-auto"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className="w-fit mx-auto tooltip hover:text-purple-600"
          style={{ fontWeight: "700" }}
        >
          GitHub
          <span className="tooltip-text">
            <span style={{ marginRight: "0.5rem" }}>❄</span> Arctic Code Vault
            Contributor <br />
            <span className="cool">(Coolest thing I'll ever do)</span>
          </span>
        </div>
      </a>
    </footer>
  );
}
