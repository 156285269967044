import React from "react";

const SkillCard = ({ skillName, skillIcon, skillUrl }) => {
  return (
    <div className="w-40 flex-none text-center p-5 rounded-xl border-2 border-gray-300 hover:bg-purple-600 hover:text-white dark:text-white cursor-pointer mx-auto">
      <a href={skillUrl} target="_blank" rel="noopener noreferrer">
        {skillIcon}
        <p className="text-xl font-semibold mt-4">{skillName}</p>
      </a>
    </div>
  );
};

export default SkillCard;
