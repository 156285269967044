import React from "react";

function About({ title }) {
  return (
    <div className="max-w-4xl mx-auto mt-12">
      <h2 className="text-2xl md:text-4xl font-extrabold text-center text-purple-600">
        {title}
      </h2>
      <p className="text-lg text-center text-gray-600 dark:text-white leading-relaxed mt-4">
        Hi, my name is Darren Inglis and I'm a{" "}
        <strong>Fullstack JavaScript</strong> developer based near Melbourne
        Australia. I specialise in <strong>React, NodeJS & MongoDB</strong>.
      </p>
    </div>
  );
}

export default About;
