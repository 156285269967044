import React from "react";
import SkillCard from "./SkillCard";

function Skills({ skills }) {
  return (
    <>
      <h2 className="text-2xl md:text-4xl font-extrabold text-center my-12 mb-6 text-purple-600">
        My Stack
      </h2>
      <div className="flex flex-col gap-2 sm:flex-row flex-wrap align-center justify-center max-w-2xl mx-auto text-gray-900">
        {skills.map((skill) => {
          return (
            <SkillCard
              key={skill.skillName}
              skillName={skill.skillName}
              skillIcon={skill.skillIcon}
              skillUrl={skill.skillUrl}
            />
          );
        })}
      </div>
    </>
  );
}

export default Skills;
