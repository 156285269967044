import React from "react";

const ProjectCard = ({ project: { title, description, tags, link } }) => {
  return (
    <div className="group w-full sm:w-1/2 mx-auto pt-6 px-6 rounded-xl border-2 border-gray-300 text-gray-900 dark:text-white">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <h1 className="text-xl text-center font-bold text-purple-600">
          {title}
        </h1>
      </a>
      <hr className="my-4" />
      <p className="text-center">{description}</p>
      <div className="mt-8 mb-8 flex flex-wrap justify-center items-center gap-2">
        {tags.map((tag) => (
          <div key={tag} className="p-4 py-1 border-2 rounded-full">
            {tag}
          </div>
        ))}
      </div>
      <div className="w-full text-center"></div>
    </div>
  );
};

export default ProjectCard;
