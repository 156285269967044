import React from "react";
import { AiFillGithub } from "react-icons/ai";

export default function OldCard({
  demoLink,
  hideLink,
  title,
  githubLink,
  img,
}) {
  return (
    <article className="w-96 overflow-hidden rounded-lg bg-white shadow-lg hover:shadow-sm cursor-pointer">
      <a href={demoLink} target="_blank" rel="noopener noreferrer">
        <img alt={title} style={{ width: "25rem" }} src={img} />
      </a>
      <header className="flex items-center justify-between text-center leading-tight mt-3">
        <h1 className="text-lg font-black text-gray-600 pb-2 text-center w-full dark:text-purple-600">
          {title}
        </h1>
        {!hideLink && (
          <div className="text-xl mr-3 mb-2 dark:text-purple-600">
            <a href={githubLink} target="_blank" rel="noopener noreferrer">
              <AiFillGithub />
            </a>
          </div>
        )}
      </header>
    </article>
  );
}
