import React, { useState } from "react";

import ImageCard from "./ImageCard";
import ProjectCard from "./ProjectCard";

export default function Projects({ imageProjects, projects }) {
  const [selectedProjects, setSelectedProjects] = useState("all");

  return (
    <>
      <h2 className="text-2xl md:text-4xl font-extrabold text-center my-12 mb-6 text-purple-600">
        My Projects
      </h2>
      <div className="flex gap-4 mb-12 justify-center text-xl">
        <button
          onClick={() => setSelectedProjects("all")}
          className="px-4 text-base py-1 border-2 rounded-full hover:bg-purple-600 hover:text-white"
        >
          All
        </button>
        <button
          onClick={() => setSelectedProjects("react")}
          className="px-4 text-base py-1 border-2 rounded-full hover:bg-purple-600 hover:text-white"
        >
          React
        </button>

        <button
          onClick={() => setSelectedProjects("nodejs")}
          className="px-4 text-base py-1 border-2 rounded-full hover:bg-purple-600 hover:text-white"
        >
          NodeJS
        </button>

        <button
          onClick={() => setSelectedProjects("misc")}
          className="px-4 text-base py-1 border-2 rounded-full hover:bg-purple-600 hover:text-white"
        >
          Misc & Fun
        </button>
      </div>

      <div className="min-w-full flex flex-wrap justify-center container gap-12 my-2 mx-auto px-4 md:px-12">
        {imageProjects &&
          selectedProjects !== "all" &&
          imageProjects
            .filter((p) => p.tag === selectedProjects)
            .map((p) => (
              <ImageCard
                key={p.title}
                title={p.title}
                img={p.img}
                demoLink={p.demoLink}
                githubLink={p.githubLink}
              />
            ))}

        {imageProjects &&
          selectedProjects === "all" &&
          imageProjects.map((p) => (
            <ImageCard
              key={p.title}
              title={p.title}
              img={p.img}
              demoLink={p.demoLink}
              githubLink={p.githubLink}
            />
          ))}

        {imageProjects &&
          selectedProjects === "nodejs" &&
          projects.map((p) => <ProjectCard key={p.title} project={p} />)}
      </div>
    </>
  );
}
