import React, { useEffect } from "react";
import AOS from "aos";

import About from "./partials/About";
import ProfileCard from "./partials/ProfileCard";
import Footer from "./partials/Footer";
import Projects from "./partials/Projects";
import Skills from "./partials/Skills";
import Switcher from "./partials/Switcher";
import data from "./data/data";
import "aos/dist/aos.css";

function App () {
  useEffect(() => {
    AOS.init({
      once: true,
    });
  });

    return (
        <div className="min-h-screen pt-10 sm:px-5 bg-gray-100 dark:bg-gray-900 dark:text-white text-purple-600">
            <div className="flex h-10 -mr-2 -mt-10">
            <Switcher />
        </div>
        <div data-aos="fade-down" data-aos-duration="800">
            <ProfileCard name={ data.name } title={ data.title } social={ data.social } />
            <div>
                <About title={ data.about.title } description={ data.about.description } />
                <Skills skills={ data.skills } />
                <Projects projects={ data.projects } imageProjects={ data.imageProjects } />
            </div>
        </div>
      <Footer />
    </div>
  );
}

export default App;
