import React from "react";
import { FaReact } from "react-icons/fa";
import { DiNodejsSmall } from "react-icons/di";
import { SiTypescript, SiRedux, SiTailwindcss } from "react-icons/si";

import github from "../images/github.jpeg";
import stripe from "../images/stripe.png";
import meetingtrain from "../images/meetingtrain.webp";
import ron from "../images/ron.webp";
import epoch from "../images/epoch.webp";
import idealocker from "../images/idealocker.png";

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
  name: "Darren Inglis",
  title: "Fullstack JavaScript Developer",
  social: {
    github: "https://github.com/darreninglis",
    twitter: "https://twitter.com/megabitlabs",
    email: "darren.inglis+website@gmail.com",
  },
  about: {
    title: "Introduction",
    description:
      "Hi, my name is Darren and I'm a full stack JavaScript developer based near Melbourne Australia. I specialise in React, NodeJS & MongoDB.",
  },
  skills: [
    {
      skillName: "ReactJS",
      skillIcon: <FaReact className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillUrl: "https://reactjs.org/",
    },
    {
      skillName: "Tailwindcss",
      skillIcon: <SiTailwindcss className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillUrl: "https://tailwindcss.com",
    },
    {
      skillName: "Redux",
      skillIcon: <SiRedux className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillUrl: "https://redux.js.org/",
    },
    {
      skillName: "TypeScript",
      skillIcon: <SiTypescript className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillUrl: "https://www.typescriptlang.org/",
    },
    {
      skillName: "NodeJs",
      skillIcon: <DiNodejsSmall className={BOOTSTRAP_FOR_SKILL_ICON} />,
      skillUrl: "https://nodejs.org/en/",
    },
  ],
  projects: [
    {
      title: "NodeJS Filesharing App",
      description:
        "This is a fun little app that allows you to share files with a link & it's optionally password protected.",
      tags: ["NodeJS", "Express", "MongoDB"],
      link: "https://github.com/darreninglis/",
    },
  ],

  imageProjects: [
    {
      title: "GitHub - Desktop UI Clone",
      img: github,
      demoLink: "https://github-desktop-ui-clone.netlify.app/",
      githubLink: "https://github.com/darreninglis/github-ui-clone",
      tag: "react",
    },
    {
      title: "Stripe - Dashboard UI Clone",
      img: stripe,
      demoLink: "https://stripe-dashboard-ui-clone.netlify.app/",
      githubLink: "https://github.com/darreninglis/stripe-ui-clone",
      tag: "react",
    },
    {
      title: "MeetingTrain - Tool",
      img: meetingtrain,
      demoLink: "https://meetingtrain.rocks/",
      githubLink: "",
      tag: "misc",
    },
    {
      title: "Ron Swanson - Sound Board",
      img: ron,
      demoLink: "https://ron-swanson-sound-app.netlify.app/",
      githubLink: "https://github.com/darreninglis/ron-swanson",
      tag: "misc",
    },
    {
      title: "Epoch - Chrome Extension",
      img: epoch,
      demoLink: "https://epoch.netlify.app/",
      githubLink: "https://github.com/darreninglis/epoch",
      tag: "misc",
    },
    {
      title: "Idealocker - Fullstack",
      img: idealocker,
      demoLink: "https://www.idealocker.app/",
      githubLink: "",
      tag: "misc",
    },
  ],
};
export default data;
